import { ApiRole, ApiRoleObject, ApiUserObject } from "./admin/users";
import { ApiResponse } from "./api";
import { get, post } from "./utils";

export interface ApiProfileObject {
  id: number;
  first_name: string;
  last_name: string;
  email_address: string;
  avatar?: string;
  roles?: ApiRole[];
  server_roles?: ApiRoleObject[];
  vacation_mode: boolean,
  vacation_replacement?: ApiUserObject,
  holidayers: number[],
}


export default {
  profile: () => get('auth/profile') as ApiResponse<ApiProfileObject>,
  saveVacation: (vacation: any) => post('auth/profile/vacation', vacation) as ApiResponse<ApiProfileObject>,
}
